import styles from './Header.module.scss'
import React, { forwardRef } from 'react';

const Header = forwardRef((props, ref) =>  {
  return (
    <div className={styles.container} ref={ref}>
      <h1 className={styles.title}>Total Computer And Communications Group</h1>
      <p className={styles.description}>TCCG provides you with a dedicated, US-based IT team, to answers questions, fix issues, and keep your business running smoothly and securely.</p>
    </div>
  );
});

export default Header;