import { Icon } from '@iconify/react';
import React, { forwardRef } from 'react';
import styles from './Services.module.scss';


const Services = forwardRef((props, ref) => {
  // Mobile Device Integration
  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.grid}>
        <div className={styles.gridElement}>
          <Icon icon="fa6-solid:computer" color="#25b148 " width="100" height="100" />
          <h2>Computing Hardware/Software</h2>
        </div>
        <div className={styles.gridElement}>
          <Icon icon="icon-park-outline:voice-one" color="#25b148 " width="100" height="100" />
          <h2>Voice + Data Communitcations</h2>
        </div>
        <div className={styles.gridElement}>
          <Icon icon="mdi:virus-off-outline" color="#25b148 " width="100" height="100" />
          <h2>Virus Erradication + Prevention</h2>
        </div>
        <div className={styles.gridElement}>
          <Icon icon="carbon:data-backup" color="#25b148 " width="100" height="100" />
          <h2>File Backup + Recovery</h2>
        </div>        
        <div className={styles.gridElement}>
          <Icon icon="carbon:network-2" color="#25b148 " width="100" height="100" />
          <h2>WAN/LAN Network Support</h2>
        </div>
        <div className={styles.gridElement}>
          <Icon icon="mdi:backup-outline" color="#25b148 " width="100" height="100" />
          <h2>Cloud Services</h2>
        </div>       
        <div className={styles.gridElement}>
          <Icon icon="et:mobile" color="#25b148 " width="100" height="100" />          
          <h2>Mobile Device Integration</h2>
        </div>
        <div className={styles.gridElement}>
          <Icon icon="fluent-mdl2:website" color="#25b148 " width="100" height="100" />
          <h2>Website and App Building</h2>
        </div>      
      </div>
      {/* <h1 style={{color: '#1DCDFE'}}>Services</h1>
      <h2>Assess and Report</h2>
      <p>We assess your company' operational performance in the IT service category End User Help and Support, free of charge, and find out how your company compares to industry standards and best practice.</p>
      
      <h2>Analyze and Define</h2>
      <p>We immerse ourselves in the End User Services Operations of a client organization to understand and define effective measures to maximize operational performance.</p>

      <h2>Implement</h2>
      <p>We support our clients during the implementation and during the pose implementation phase to ensure defined improvement measures are implemented.</p>

      <h2>Monitor and Control</h2>
      <p>We monitor progress and provide extensive "on the job training" to ensure the implemented improvement measures yield optimal results.</p> */}
    </div>
  );
});

export default Services;