import styles from './Clients.module.scss';
import React, { forwardRef } from 'react';
import { Icon } from '@iconify/react';

const Clients = forwardRef((props, ref) => {
  return (
    <div className={styles.container} ref={ref}>
      <div className={`${styles.clientContainer} ${styles.client1}`}>
        <Icon icon="ion:game-controller" width="60px" height="60px"  color="#04242d" />
        <p>We turned around the PC Desk IT Support for a large gaming company with over 9 billion dollars of revenue.</p>
      </div>
      <div className={`${styles.clientContainer} ${styles.client2}`}>
        <Icon icon="tabler:report-search" width="60px" height="60px"  color="#04242d" />
        <p>We monitored IT support for a global credit reporting company with over 17,000 employees.</p>
      </div>
      <div className={`${styles.clientContainer} ${styles.client3}`}>
        <Icon icon="ion:documents" width="60px" height="60px"  color="#04242d"/>
        <p>We implemented IT procedures for a leading document management enterprise with over 22 billion dollars of revenue.</p>
      </div>
      <div className={`${styles.clientContainer} ${styles.client4}`}>
        <Icon icon="material-symbols:cell-tower" width="60px" height="60px"  color="#04242d" />
        <p>We developed IT procedures for a global telecomunication company with over 10,000 employees.</p>
      </div>
    </div>
  );
});

export default Clients;