import './App.css';
import React, { useRef } from 'react';
import NavBar from './Components/NavBar/NavBar';
import Header from './Pages/Header/Header';
import About from "./Pages/About/About"
import Services from './Pages/Services/Services';
import Clients from './Pages/Clients/Clients';
import Contact from './Pages/Contact/Contact';

const App = () => {
 // Define the scrollToSection function
 const scrollToSection = (elementRef) => {
    if (elementRef && elementRef.current) {
        window.scrollTo({ 
        top: elementRef.current.offsetTop - 70,
        behavior: 'smooth' 
      });
    }
    else {
      console.error("Element reference is null or undefined.");
    }
  };


  const headerRef = useRef(null)
  const aboutRef = useRef(null)
  const servicesRef = useRef(null)
  const clientsRef = useRef(null)
  const contactRef = useRef(null)



  return (
    <div>
      <Header ref={headerRef} id="Header"/> 
      <About ref={aboutRef} id="About"/>
      <Services ref={servicesRef} id="Services"/>
      <Clients ref={clientsRef} id="Clients"/>
      <Contact ref={contactRef} id="Contact"/> 
      <NavBar scrollToSection={scrollToSection} headerRef={headerRef} aboutRef={aboutRef} servicesRef={servicesRef} clientsRef={clientsRef} contactRef={contactRef}/>
    </div>
  );
}

export default App;
