import styles from './About.module.scss';
import React, { forwardRef } from 'react';
import { Icon } from '@iconify/react';

const About = forwardRef((props, ref) => {
  return (
    <div className={styles.container} ref={ref}>
      <div className={`${styles.row} ${styles.row1}`}>  
      <Icon icon="solar:dollar-linear" width="50px" height="50px"  style={{color: 'white', marginRight: "50px"}} />      
        <div style={{marginLeft: "30px"}}>
          <h1 className={styles.heading}>Cut IT Maintenance Spending</h1>
          <p className={styles.description}>Reduce IT costs by up to 70% compared to the OEM, and free up your budget for growth and innovation.</p>
        </div>
      </div>
      <div className={`${styles.row} ${styles.row2}`}>    
      <Icon icon="simple-line-icons:check" width="50px" height="50px"  style={{color: "white"}} />   
        <div style={{marginLeft: "30px"}}>
          <h1 className={styles.heading}>Choose Quality</h1>
          <p className={styles.description}>Never compromise on performance or service quality with expert support from our engineers.</p>
        </div>
      </div>
      <div className={`${styles.row} ${styles.row3}`}>
        <Icon icon="grommet-icons:upgrade" width="50px" height="50px"  style={{color: "white"}} /> 
        <div style={{marginLeft: "30px"}}>
          <h1 className={styles.heading}>Drive Productivity</h1>
          <p className={styles.description}> Minimize downtime with customizable SLAs (Service Level Agreements) and responsive requests.</p>
        </div>
      </div>

    </div>
  );
});

export default About;