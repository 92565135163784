import React, { useEffect, useState } from 'react';
import styles from './NavBar.module.scss';
import { Icon } from '@iconify/react';

const NavBar =  ({ scrollToSection,  headerRef, aboutRef, servicesRef, clientsRef, contactRef }) => {
  const [displayDropdown, setDisplayDropdown] = useState(false)
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const isScrolling = scrollTop > 529; // Adjust this value based on when you want the color change to happen
      setScrolling(isScrolling);
    };
    window.addEventListener('scroll', handleScroll);
    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function handleDropdownClick(){

    if(displayDropdown){
      setDisplayDropdown(false)
    }
    else{
      setDisplayDropdown(true)
    }
  }

  const navbarStyle = {
    backgroundColor: scrolling ? '#25b148 ' : 'transparent',
    transition: 'background-color 0.3s ease',
  };

  const [hoveredButton, setHoveredButton] = useState(null);

  // Button styles with hover effect
  const getButtonStyle = (buttonName) => ({
    color: hoveredButton === buttonName && scrolling ? 'black' : '', // Hover effect
  });
  


  return (
    <>
      <div className={styles.container} style={navbarStyle}>
        <div className={styles.logo}/>
        <div className={styles.nav}>
            <button
            style={getButtonStyle('about')}
            onMouseEnter={() => setHoveredButton('about')}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={() => scrollToSection(aboutRef)}
          >
            About
          </button>
          <button
            style={getButtonStyle('services')}
            onMouseEnter={() => setHoveredButton('services')}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={() => scrollToSection(servicesRef)}
          >
            Services
          </button>
          <button
            style={getButtonStyle('clients')}
            onMouseEnter={() => setHoveredButton('clients')}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={() => scrollToSection(clientsRef)}
          >
            Clients
          </button>
          <button
            style={getButtonStyle('contact')}
            onMouseEnter={() => setHoveredButton('contact')}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={() => scrollToSection(contactRef)}
          >
            Contact
          </button>
        </div>
        <Icon onClick={handleDropdownClick} icon="material-symbols:menu" width="2em" height="2em" color="white" className={styles.menuButton} />
      </div>

      <div  className={`${styles.dropdown} ${displayDropdown ? styles.dropdownVisible : ''}`}>
        <button className={styles.mobileDropdownButton} onClick={() => scrollToSection(aboutRef)}>About</button>
        <button className={styles.mobileDropdownButton} onClick={() => scrollToSection(servicesRef)}>Services</button>
        <button className={styles.mobileDropdownButton} onClick={() => scrollToSection(clientsRef)}>Clients</button>
        <button style={{borderBottom: '1.5px'}} className={styles.mobileDropdownButton} onClick={() => scrollToSection(contactRef)}>Contact</button>
      </div>
    </>
  );
}

export default NavBar;