import styles from './Contact.module.scss'
import React, { forwardRef } from 'react';

const Contact = forwardRef((props, ref) =>  {
  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.contactContainer}>
        <h3 className={styles.heading}>Address</h3>
        <p className={styles.info}>2670 N Federal Highway <br/> Lighthouse Point, FL</p>
      </div>
      <div className={styles.contactContainer}>
        <h3 className={styles.heading}>Email</h3>
        <a className={styles.info} href="info@tccginc.com">info@tccginc.com</a>
      </div>
      <div className={styles.contactContainer}>
        <h3 className={styles.heading}>Telephone/Fax</h3>
        <a className={styles.info} href="tel:5613619446">561-361-9446</a>
      </div>
    </div>
  );
});

export default Contact;